.header {
  background: #ffffff;
  height:rem-calc(60);
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:99;
  border-bottom:rem-calc(1) solid #f5f5f5;
  &.shadow-header {
      box-shadow:0 rem-calc(4) rem-calc(32) 0 rgba(0,0,0,0.15);
  }
  .top-menu {
    .logo {
      float:left;

      line-height:1;
      font-weight:900;
      font-size:rem-calc(22);
      margin-top:rem-calc(19);
      a {
        color:#5053AB;
        span {
          background:#EF59AC;
          color:#fff;
          line-height:0;
          font-size:rem-calc(14);
          position:relative;
          padding:rem-calc(0 10);
          top:rem-calc(-2);
          left:rem-calc(5);
          border-radius:rem-calc(2);
        }
      }
    }
    .header-menu {
      float:right;
      button {
        margin-top:rem-calc(18);
        color:#5053AB;
        box-shadow:none;
        border:none;
        outline:none;
        &:after {
          display:none;
        }
        span {
          width:rem-calc(25);
          height:rem-calc(2);
          background:#5053AB;
          display:block;
          &:nth-of-type(2) {
            margin-top:rem-calc(5);
          }
          &:nth-of-type(3) {
            margin-top:rem-calc(5);
          }
        }
        &:hover {
          span {
            background:#777;
          }
        }
      }
      .dropdown-content {
        li {
          padding:rem-calc(5 10);
          a {
            line-height:1.3;
          }
        }
      }
      ul {
        list-style:none;
        margin:0;
        padding:0;
        &.right {

        }
        li {
          display:inline-block;
          a {

            color:#5053AB;
            line-height:rem-calc(60);
            display:block;
            font-weight:700;
            padding:rem-calc(0 15);
            &:focus {
              background:none;
            }
            .badge {
              padding:rem-calc(2 7);
              height:rem-calc(20);
              border-radius:rem-calc(3);
              text-align:center;
              display:inline-block;
              position:relative;
              top:rem-calc(-2);
            }
          }
        }
      }
    }
  }
}
