.payment-page-wrapper {
  padding:rem-calc(50 0);
  background:#f9f9f9;
  .service-details-box {
    padding:rem-calc(20);
    background:#fff;
    border:rem-calc(2) solid #eee;
    margin-bottom:rem-calc(20);
    overflow:hidden;
    position:relative;
    h5 {
      font-size:rem-calc(12);
      line-height:rem-calc(20);
      color:#666;
      text-transform:uppercase;
      font-weight:300;
      margin-bottom:0;
    }
    h3 {
      font-size:rem-calc(18);
      line-height:rem-calc(24);
      padding-right:rem-calc(100);
      a {
        color:#5053AB;
        font-weight:700;

      }
    }
    .service-price-delivery {
      position:absolute;
      right:rem-calc(20);
      top:rem-calc(20);
      font-size:rem-calc(18);
      font-weight:700;
      text-align:center;
      color:#5053AB;
      letter-spacing:rem-calc(-1);
      small {
        font-size:rem-calc(10);
        font-weight:300;
        letter-spacing:rem-calc(1);
        margin-left:rem-calc(5);
      }
      span {
        display:block;
        background:#EF59AC;
        color:#fff;
        border-radius:rem-calc(3);
      }
    }
    ul {
      list-style:none;
      margin:0;
      padding:0;
      li {
        display:block;
        font-size:rem-calc(14);
        padding:rem-calc(4 0);
        font-weight:300;
        i {
          color:#aaa;
          position:relative;
          top:rem-calc(2);
        }
        strong {

        }
      }
    }
  }
  .payment {
    padding:rem-calc(20);
    background:#fff;
    border:rem-calc(2) solid #eee;
    margin-bottom:rem-calc(20);
    h4 {
      font-size:rem-calc(12);
      line-height:rem-calc(20);
      color:#666;
      text-transform:uppercase;
      font-weight:300;
      margin-bottom:0;
    }
    .paypal,
    .stripe {
      h5 {
        font-size:rem-calc(16);
        font-weight:700;

        line-height:rem-calc(28);
      }
      .btn {
        line-height:rem-calc(16);
        font-size:rem-calc(16);
        font-weight:700;

      }
    }
    .paypal {
      padding-bottom:rem-calc(20);

    }
    .or {
      text-align:center;
      position:relative;

      &:after {
        content:'';
        border-top:rem-calc(1) dashed #ddd;
        display:block;
        position:absolute;
        top:rem-calc(14);
        left:0;
        right:0;
        z-index:1;
      }
      span {
        background:#fff;
        font-weight:700;

        z-index:2;
        position:relative;
        display:inline-block;
        padding:rem-calc(0 10);
        color:#aaa;
        font-size:rem-calc(18);
      }
    }
    .stripe {
      .form-field {
        margin-bottom:rem-calc(15);
        label {
          display:block;
          font-size:rem-calc(14);
          font-weight:300;

          &.not-visible {
            visibility:hidden;
          }
        }
        input,
        select {
          border-radius:0;
          padding:rem-calc(0 5);
        }
        .no-padding {
          padding:0;
        }
      }
    }
  }
}