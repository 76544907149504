.services {
  padding:rem-calc(50 0);
  background:#fff;
  .container {
    .block-title {
      margin-bottom:rem-calc(30);
      h3 {
        color:#5053AB;
        font-size:rem-calc(32);
        font-weight:700;

        border-bottom:rem-calc(6) solid #5053AB;
        display:inline-block;
        margin:0;
        padding-bottom:rem-calc(6);
      }
    }
    .service {
      border:rem-calc(1) solid #e5e5e5;
      margin-bottom:rem-calc(20);
      background:#fff;
      box-shadow:0 rem-calc(4) rem-calc(32) 0 rgba(0,0,0,0.15);
      .service-image {
        height:rem-calc(210);
        overflow:hidden;
        img {
          object-fit:cover;
          height:rem-calc(210);
          width:100%;
        }
        .slick-prev,
        .slick-next {
          visibility:hidden;
        }
        &:hover {
          .slick-prev,
          .slick-next {
            visibility:visible;
          }
        }
      }
      .service-title {
        padding:rem-calc(15 10);
        border-top:rem-calc(1) solid #eee;
        a {
          font-size:rem-calc(16);
          color:#212121;
          font-weight:500;
          &:hover {
            color:#444;
            text-decoration:underline;
          }
        }
      }
      .service-meta {
        border-top:rem-calc(1) solid #eee;
        padding:rem-calc(10);
        overflow:hidden;
        font-size:rem-calc(16);

        font-weight:500;
        .price {
          color:#444;
          float:left;
          i {
            color:#FFC14D;
          }
          small {
            padding-left:rem-calc(3);
          }
        }
        .delivery {
          float:right;
          i {
            color:#7B7FC2;
          }
        }
        i {
          font-size:rem-calc(14);
          position:relative;
          top:rem-calc(2);
        }
      }
    }
    .more-services {
      text-align:center;
      margin-top:rem-calc(50);
      a {

        font-weight:300;
        line-height:1;
        font-size:rem-calc(16);
      }
    }
    .services-pagination {
      text-align:center;
      margin:rem-calc(30) auto;
      ul {
        list-style:none;
        margin:0;
        padding:0;
        font-size:0;
        li {
          display:inline-block;

          a {
            background:none;
            color:#5053AB;
            padding:rem-calc(4 12);
            display:block;

            border-radius:rem-calc(4);
            border:rem-calc(2) solid #5053AB;
            margin-right:rem-calc(5);
            font-size:rem-calc(16);
            i {
              line-height:1;
              position:relative;
              top:rem-calc(3);
            }
          }
          &.active {
            a {
              background:#5053AB;
              color:#fff;
            }
          }
        }
      }
    }
  }
}